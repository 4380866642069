// @ts-strict-ignore
import _ from 'lodash';
import { TableBuilderSimpleTable } from '@/tableBuilder/TableBuilderSimpleTable.organism';
import { TableBuilderConditionTable } from '@/tableBuilder/TableBuilderConditionTable.organism';
import { Accordion, Alert } from 'react-bootstrap';
import { Icon } from '@/core/Icon.atom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  provideVisualizationData,
  serializeDisplayRange,
} from '@/annotation/ckEditorPlugins/components/content.utilities';

import { TableBuilderHeaders, TableBuilderSort } from '@/tableBuilder/tableBuilder.store';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { RangeExport } from '@/trendData/duration.store';
import { TableViewer } from '@/tableBuilder/tableViewer/TableViewer.molecule';
import { ChartSettingsInterface } from './tableViewer/ChartSettings.molecule';
import { NewLineToBr } from '@/core/NewLineToBr.atom';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { TableBuilderMode, TableTextFormatterIF } from '@/tableBuilder/tableBuilder.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { CustomToggle } from '@/core/CustomToggle.atom';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { ParametersMap } from '@/utilities/formula.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';

export interface TableBuilderPropsOnlyIF {
  canEdit: boolean;
  isSimpleMode: boolean;
  startMode: boolean;
  isLoading: boolean;
  hasOnlyStringSeries: boolean;
  hasNumericAndStringSeries: boolean;
  hasOnlyStringMetrics: boolean;
  hasNumericAndStringMetrics: boolean;
  setCellText: (key: string, value: string, id?: string) => void;
  setHeaderText: (columnKey: string, text: string) => void;
  setColumnFilter: (key: string, filter: TableColumnFilter) => void;
  simpleTableData: any;
  conditionTableData: any;
  headers: TableBuilderHeaders;
  simpleColumns: any;
  conditionColumns: any;
  isTransposed: boolean;
  useSignalColorsInChart: boolean;
  isTableStriped: boolean;
  isPresentationMode: boolean;
  isViewOnlyMode: boolean;
  displayRange: RangeExport;
  timezone: { name: string };
  displayMetricOnTrend: (metricId: string, formulaItemId: string, start: number, end: number, event: any) => void;
  columnToThresholdsForCondition: Object;
  columnToThresholdsForSimple: Object;
  distinctStringValueMap: { [mode: string]: { [columnKey: string]: string[] } };
  textFormatter: TableTextFormatterIF;
  moveColumn: (key: string, newKey: string) => void;
  canSort: boolean;
  maxSortLevel: number;
  sortByColumn: (key: string, direction: string) => void;
  removeColumn: (key: string) => void;
  itemFilters: { [id: string]: { filter: TableColumnFilter } };
  itemSorts: { [id: string]: TableBuilderSort };
  setActiveTool: (toolName: string) => void;
  fetchFailedMessage: { message: string; apiMessage: string } | undefined;
  setActiveRefElement: any;
  showChartView: boolean;
  chartViewSettings: ChartSettingsInterface;
  fetchStringColumnValues: (columnKey: string, isStringColumn: boolean, cancellationGroup: string) => void;
  setChartViewSettings: (property: any) => void;
  onContentLoad?: () => void;
  afterChartUpdate?: () => void;
  chartColors: ParametersMap;
}

export let TableBuilderPropsOnly: React.FunctionComponent<TableBuilderPropsOnlyIF> = (props) => {
  const {
    isSimpleMode,
    startMode,
    canEdit,
    setCellText,
    setColumnFilter,
    setHeaderText,
    isLoading,
    hasOnlyStringMetrics,
    hasNumericAndStringMetrics,
    hasNumericAndStringSeries,
    hasOnlyStringSeries,
    simpleTableData,
    conditionTableData,
    headers,
    canSort,
    displayMetricOnTrend,
    displayRange,
    isPresentationMode,
    isTransposed,
    useSignalColorsInChart,
    maxSortLevel,
    textFormatter,
    itemFilters,
    isTableStriped,
    columnToThresholdsForCondition,
    columnToThresholdsForSimple,
    distinctStringValueMap,
    moveColumn,
    sortByColumn,
    removeColumn,
    isViewOnlyMode,
    itemSorts,
    conditionColumns,
    simpleColumns,
    timezone,
    fetchFailedMessage,
    setActiveRefElement,
    setActiveTool,
    showChartView,
    chartViewSettings,
    onContentLoad,
    fetchStringColumnValues,
    afterChartUpdate,
    chartColors,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    onContentLoad?.();
  }, []);

  const hasFetchError = !_.isEmpty(fetchFailedMessage?.message);

  if (headlessRenderMode() && !startMode) {
    provideVisualizationData({ ...props, visualization: Visualization.TABLE }, { displayRange: serializeDisplayRange });
  }

  // Ensures alert is not centered in headless mode, because otherwise renderer only captures empty space
  const alertWrapper = (alert) => (
    <div className={headlessRenderMode() ? '' : 'flexRowContainer flexCenter flexFill'}>{alert}</div>
  );

  const statColumnIsSelected = () =>
    _.chain(simpleColumns)
      .some((col) => col.key.startsWith('statistic') || col.key === COLUMNS_AND_STATS.metricValue.key)
      .value();

  const showChart = showChartView && statColumnIsSelected();

  return (
    <>
      <div className={`flexRowContainer flexFill${isPresentationMode ? '' : ' p20'}`}>
        {((!statColumnIsSelected() && showChartView) || _.isEmpty(simpleColumns)) && !startMode && isSimpleMode && (
          <div className="flexCenter flexColumnContainer flexFill">
            <Alert variant="info" className="width-400 fs16 p25 screenshotSizeToContent" transition={false}>
              <p>
                <Icon icon={isTransposed ? 'fc-add-row' : 'fc-add-column'} extraClassNames="p5" type="theme" />
                <span> {t(isTransposed ? 'TABLE_BUILDER.SELECT_ROW' : 'TABLE_BUILDER.SELECT_COLUMN')} </span>
              </p>
            </Alert>
          </div>
        )}
        {simpleColumns.length > 0 && isSimpleMode && !startMode && !hasFetchError && (
          <div className={showChart ? 'flexFill flexColumnContainer' : 'overflowYAuto'} ref={setActiveRefElement}>
            {!showChartView && (
              <TableBuilderSimpleTable
                simpleTableData={simpleTableData}
                isLoading={isLoading}
                columns={simpleColumns}
                headers={headers}
                isTransposed={isTransposed}
                isStriped={isTableStriped}
                isPresentationMode={isPresentationMode}
                isViewOnlyMode={isViewOnlyMode}
                displayRange={displayRange}
                timezone={timezone}
                displayMetricOnTrend={displayMetricOnTrend}
                setCellText={setCellText}
                setHeaderText={setHeaderText}
                setColumnFilter={setColumnFilter}
                columnToThresholds={columnToThresholdsForSimple}
                hasOnlyStringSeries={hasOnlyStringSeries}
                hasNumericAndStringSeries={hasNumericAndStringSeries}
                hasOnlyStringMetrics={hasOnlyStringMetrics}
                hasNumericAndStringMetrics={hasNumericAndStringMetrics}
                distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Simple]}
                textFormatter={textFormatter}
                canSort={canSort}
                maxSortLevel={maxSortLevel}
                moveColumn={moveColumn}
                removeColumn={removeColumn}
                sortByColumn={sortByColumn}
              />
            )}
            {showChart && (
              <TableViewer
                testId="tableChartViewer"
                storeSettings={chartViewSettings}
                simpleTableData={simpleTableData}
                columns={simpleColumns}
                afterChartUpdate={afterChartUpdate}
                isTransposed={isTransposed}
                useSignalColorsInChart={useSignalColorsInChart}
                chartColors={chartColors}
              />
            )}
          </div>
        )}
        {!isSimpleMode && !startMode && !hasFetchError && (
          <div className="overflowYAuto" ref={setActiveRefElement}>
            <TableBuilderConditionTable
              tableData={conditionTableData}
              isLoading={isLoading}
              headers={headers}
              columns={conditionColumns}
              isTransposed={isTransposed}
              isStriped={isTableStriped}
              isPresentationMode={isPresentationMode}
              canEdit={canEdit}
              timezone={timezone}
              setCellText={setCellText}
              setHeaderText={setHeaderText}
              setColumnFilter={setColumnFilter}
              itemFilters={itemFilters}
              fetchStringColumnValues={fetchStringColumnValues}
              columnToThresholds={columnToThresholdsForCondition}
              distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Condition]}
              textFormatter={textFormatter}
              canSort={canSort}
              itemSorts={itemSorts}
              maxSortLevel={maxSortLevel}
              moveColumn={moveColumn}
              removeColumn={removeColumn}
              displayMetricOnTrend={displayMetricOnTrend}
              sortByColumn={sortByColumn}
            />
          </div>
        )}
        {hasFetchError &&
          !startMode &&
          alertWrapper(
            <Alert variant="info" className="max-width-400 screenshotSizeToContent fs fs16" transition={false}>
              <NewLineToBr lineToBreak={fetchFailedMessage?.message} />

              {fetchFailedMessage.apiMessage && (
                <Accordion className="pt10 font-size-smaller">
                  <CustomToggle
                    eventKey="0"
                    Component={({ onClick, label, isCardOpen }) => (
                      <div
                        onClick={onClick}
                        data-testid="detailsToggle"
                        className="sq-text-primary cursorPointer flexColumnContainer flexAlignCenter">
                        <span className="pr5">{t(label)}</span>
                        <Icon icon={isCardOpen ? 'fa-chevron-down' : 'fa-chevron-right'} />
                      </div>
                    )}
                    label="TABLE_BUILDER.VIEW_DETAILS"
                  />
                  <Accordion.Collapse eventKey="0" data-testid="apiMessage">
                    <span>{fetchFailedMessage.apiMessage}</span>
                  </Accordion.Collapse>
                </Accordion>
              )}
            </Alert>,
          )}
        {startMode &&
          alertWrapper(
            <Alert variant="info" className="width-400 fs16 p25 screenshotSizeToContent" transition={false}>
              {!canEdit && <p>{t('TABLE_BUILDER.START_MODE_NO_CONTENT')}</p>}
              {canEdit && ( // TODO CRAB-27446: <p> cannot appear as a descendant of <p>
                <p>
                  <p>{t('TABLE_BUILDER.START_MODE')}</p>
                  <a className="cursorPointer" onClick={() => setActiveTool(TREND_TOOLS.THRESHOLD_METRIC)}>
                    <Icon icon="fa-plus-circle" extraClassNames="p5" type="theme" testId="addFirstMetricButton" />
                    <span>{t('TABLE_BUILDER.ADD_METRIC')}</span>
                  </a>
                </p>
              )}
            </Alert>,
          )}
      </div>
    </>
  );
};
